import React from 'react'
import styled from 'styled-components'
import TextSkeleton, { Line0TextSkeleton } from '../Text/views/TextSkeleton'
import {
  Wrapper0JobElement,
  StyledLogoWrapper0JobElement,
  StyledJobInfo0JobElement,
  StyledTopLine0JobElement,
  StyledEmploymentType0JobElement,
  StyledFooter0JobElement,
  StyledLocationWrapper0JobElement,
  StyledLocationIcon0JobElement,
  StyledSourceWrapper0JobElement,
  StyledSourceIcon0JobElement,
  StyledDate0JobElement,
} from '.'

const Wrapper = styled(Wrapper0JobElement)`
  &:hover {
    background-color: white;
  }
`

const StyledLogoWrapper = styled(StyledLogoWrapper0JobElement)`
  background-color: ${(props) => props.theme.color.skeletonScreen};
`

const StyledEmployerSkeleton = styled(TextSkeleton)`
  width: 130px;
  ${Line0TextSkeleton} {
    border-width: 14px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: 100px;
    ${Line0TextSkeleton} {
      border-width: 11px;
      margin-top: 3px;
    }
  }
`

const StyledEmploymentType = styled(StyledEmploymentType0JobElement)`
  color: #ffebeb;
  padding: 1px 8px 0;
`

const StyledTitleSkeleton = styled(TextSkeleton)`
  margin-top: 8px;
  ${Line0TextSkeleton} {
    border-width: 19px;
    margin-bottom: 6px;
    margin-top: 0;
  }
  display: block;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    display: none;
    margin-top: 4px;
    ${Line0TextSkeleton} {
      margin-bottom: 4px;
      margin-top: 1px;
    }
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    display: block;
    ${Line0TextSkeleton} {
      border-width: 14px;
      margin-bottom: 3.4px;
      margin-top: 2px;
    }
  }
  @media (max-width: ${(props) => props.theme.mobileS.breakPoint + 'px'}) {
    margin-top: 2px;
    ${Line0TextSkeleton} {
      margin-bottom: 2px;
      margin-top: 3.5px;
    }
  }
`

const StyledTitleSkeletonOneLine = styled(StyledTitleSkeleton)`
  display: none;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    display: block;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    display: none;
  }
`

const StyledLocationSkeleton = styled(TextSkeleton)`
  width: 70px;
  ${Line0TextSkeleton} {
    border-width: 11px;
    margin-top: 2px;
  }
`

const StyledSourceSkeleton = styled(StyledLocationSkeleton)`
  width: 100px;
`

const StyledDateSkeleton = styled(StyledLocationSkeleton)`
  width: 60px;
`

const JobElement = ({ data, loading, isWidget, ...props }) => {
  return (
    <Wrapper isWidget={isWidget} {...props}>
      <StyledLogoWrapper />
      <StyledJobInfo0JobElement>
        <StyledTopLine0JobElement>
          <StyledEmployerSkeleton lines={1} />
          <StyledEmploymentType>Teilzeit</StyledEmploymentType>
        </StyledTopLine0JobElement>
        <StyledTitleSkeleton lines={2} lastWidth="50%" />
        <StyledTitleSkeletonOneLine lines={1} lastWidth="80%" />
        <StyledFooter0JobElement>
          <StyledLocationWrapper0JobElement isWidget={isWidget}>
            <StyledLocationIcon0JobElement />
            <StyledLocationSkeleton lines={1} />
          </StyledLocationWrapper0JobElement>
          <StyledSourceWrapper0JobElement>
            <StyledSourceIcon0JobElement />
            <StyledSourceSkeleton lines={1} />
          </StyledSourceWrapper0JobElement>
          {!isWidget && (
            <StyledDate0JobElement>
              <StyledDateSkeleton lines={1} />
            </StyledDate0JobElement>
          )}
        </StyledFooter0JobElement>
      </StyledJobInfo0JobElement>
    </Wrapper>
  )
}

export default JobElement
