import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

const Wrapper = styled(FormControlLabel)``
const StyledCheckbox = styled(Checkbox)`
  color: ${(props) => props.theme.color.stol};
  &.Mui-checked {
    color: ${(props) => props.theme.color.stol};
  }
`

const CheckBox = ({ checked, value, disabled, label, labelPlacement, onChange, ...props }) => (
  <Wrapper
    disabled={disabled}
    control={<StyledCheckbox checked={checked} value={value} onChange={onChange} />}
    label={label}
    labelPlacement={labelPlacement}
    {...props}
  />
)

CheckBox.propTypes = {
  checked: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  labelPlacement: PropTypes.oneOf(['end', 'start', 'top', 'bottom']),
}

CheckBox.defaultProps = {}

export default CheckBox
