import React, { memo, useState } from 'react'
import styled from 'styled-components'
import Button from '../../../../components/Button'
import translate from '../../../../modules/translate'
import TitleWidgetNakedLineArticle from '../../../../topics/Article/widgets_line/WidgetNakedLineArticle/components/TitleWidgetNakedLineArticle'
import PropTypes from 'prop-types'
import JobElement from '../../../../components/JobElement'
import JobIcon from '../../../../components/JobElement/images/JobIcon'
import ErrorBoundary from '../../../../topics/Error/widgets/ErrorBoundary'
import SkeletonJobElement from '../../../../components/JobElement/skeleton'
import fetchData, { actionGET } from '../../../../modules/fetch-data'
import { Async } from 'react-async'
import { useScreenSize } from '../../../../modules/media-query'
import errorLog from '../../../../modules/error-log'
import isStolApp from '../../../../modules/is-stolapp'
import ReactGA from 'react-ga'
import JobPoweredByBlock from '../../../../topics/JobWidgetPage/components/JobPoweredByBlock'
import isServerSide from '../../../../modules/is-server-side'
import routes from '../../../../topics/routes'
import branchchoice_options from './branchchoice_options'
import BranchChoice from './BranchChoice'
import targetGoal from './img/target-goal.svg'
import targetGoalWhite from './img/target-goal-white.svg'

const Wrapper = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  align-items: center;
  background-color: #e8e8e8;
  padding: 0 calc(${(props) => props.theme.sizes.gapCol} - 7px) 0;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding: 0;
  }
`

const StyledTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 25px 0 18px;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-bottom: 25px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin: 20px 0;
  }
`

const StyledJobsLogo = styled(JobIcon)`
  color: ${(props) => props.theme.color.textSecondary};
  width: 40px;
  height: 40px;
  margin-right: 10px;
  margin-bottom: 5px;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: 25px;
    height: 25px;
    margin-right: 6px;
    margin-bottom: 3px;
  }
`

const StyledTitle = styled(TitleWidgetNakedLineArticle)`
  margin: 0;
`

const StyledJobsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const StyledJobElementWrapper = styled.div`
  display: flex;
  flex-basis: calc(50% - 14px);
  max-width: calc(50% - 14px);
  margin: 7px;
  &:nth-child(n + ${(props) => props.numberItems.desktop + 1}) {
    display: none;
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-basis: 100%;
    max-width: 100%;
    margin: 0;
    &:nth-child(n + ${(props) => props.numberItems.desktop + 1}) {
      display: flex;
    }
    &:nth-child(n + ${(props) => props.numberItems.tablet + 1}) {
      display: none;
    }
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    &:nth-child(n + ${(props) => props.numberItems.tablet + 1}) {
      display: flex;
    }
    &:nth-child(n + ${(props) => props.numberItems.mobile + 1}) {
      display: none;
    }
  }
`

const StyledAllButton = styled(Button)`
  padding: 10px 40px;
  background-color: white;
  font-size: 16px;

  order: 1;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding: 7px 18px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: 15px;
  }
`
const StyledAllButtonWitBranchChoice = styled(Button)`
  padding: 10px 40px;
  background-color: white;
  font-size: 16px;

  order: 1;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding: 7px 18px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: 15px;
    margin-left: auto;
  }
`
const StyledAllButtonBranchChoice = styled(StyledAllButton)`
  display: flex;
  margin-left: 10px;
  padding: 10.5px 40px 6.7px;

  &:hover {
    #branchchoicesvg {
      background-image: url(${() => targetGoalWhite});
    }
  }

  &:focus {
    #branchchoicesvg {
      background-image: url(${() => targetGoalWhite});
    }
  }

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    display: none;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    display: none;
  }
`
const StyledAllButtonBranchChoiceMobile = styled(StyledAllButton)`
  display: none;
  min-width: unset;
  padding: 6px;
  background-color: white;
  &:active {
    background-color: #d41d24 !important;

    #branchchoicesvg {
      background-image: url(${() => targetGoalWhite});
    }
    #branchchoicebuttonmobile {
      background-color: #d41d24 !important;
    }
  }

  &:hover {
    background-color: white;
  }

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    display: block;
    padding-top: 7px;
    margin-left: 10px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding: 6px;
    display: block;
    margin-right: auto;
    margin-left: 10px;
  }
`

const StyledPoweredBy = styled(JobPoweredByBlock)`
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding: 18px 40px 15px;
  }
`

const StyledBranchChoiceSvg = styled.div`
  background-image: url(${() => targetGoal});
  position: relative;
  top: -1px;
  width: 22px;
  height: 22px;
  right: -3px;
`

const StyledBranchChoiceSvgMobile = styled.div`
  background-image: url(${() => targetGoal});
  position: relative;
  top: 0px;
  width: 20px;
  height: 20px;
  right: 0px;
`
const ButtonHolderBranchChoiceMobile = styled.div`
  display: flex;
`

const StyledJobElement = styled(JobElement)``

const BlockJobs = ({ numberItems, slotIds, title, TitleIcon, allButtonText, loading, isSTN, ...props }) => {
  const [stateShowBranchChoice, setShowBranchChoice] = useState({
    show: false,
    urlParameters: '',
  })
  let screen = useScreenSize()
  if (props.forceScreen) {
    screen = props.forceScreen
  } else {
    if (screen === 'mobileS') {
      screen = 'mobile'
    }
    if (!isServerSide() && isStolApp()) {
      if (window.innerWidth <= 450) {
        screen = 'mobileApp'
      } else {
        screen = 'tabletApp'
      }
    }
  }
  const errorOptions = {
    component: translate('jobs'),
    link: '/',
    logError: 'BlockJobs index',
  }

  // const AllButton = () => (
  //   <StyledAllButton to={routes.getOptions('special.jobwidget').path}>
  //     {translate('jobs_block_all_jobs')}
  //   </StyledAllButton>
  // )
  function showBranchChoice(run) {
    if (stateShowBranchChoice.show) {
      setShowBranchChoice({ show: false })

      run({
        path:
          slotIds[screen] +
          (window.localStorage.getItem('jobpreferences')
            ? '&labels=' + JSON.parse(window.localStorage.getItem('jobpreferences')).toString()
            : ''),
      })
    } else {
      setShowBranchChoice({ show: true })
    }
  }
  let shouldBranchChoiceButtonLoad = false
  if (
    !isSTN &&
    (slotIds[screen].includes('fbf8f8a3-8364-43a3-a54b-eef3badd94cb') ||
      slotIds[screen].includes('322fd3cd-ec08-481f-8167-6c6edc3f05e0') ||
      slotIds[screen].includes('9fb67308-e690-4059-a641-a4e680f74f04') ||
      slotIds[screen].includes('d07c980d-f8fe-405f-b638-aeb06413e93a') ||
      slotIds[screen].includes('973b5803-8d37-487d-bfe4-5c35d8e6e0ad') ||
      slotIds[screen].includes('59a49673-67ff-48c7-84ec-161dad52b119') ||
      slotIds[screen].includes('35357643-649a-4620-9fcf-8e62a001467e') ||
      slotIds[screen].includes('07cf37dd-47ba-43b0-a726-6080988dcd4b') ||
      slotIds[screen].includes('0cbc8a33-5745-4a0a-953e-7e39b303402b') ||
      slotIds[screen].includes('616a88f6-b083-4472-b191-143f2ec7dbb8') ||
      slotIds[screen].includes('0f216bf8-4ec9-4f6c-90fc-6af3738281e6'))
  ) {
    shouldBranchChoiceButtonLoad = true
  }
  let jobPreferenceParams = null
  if (!isServerSide()) {
    jobPreferenceParams =
      slotIds[screen] +
      (window.localStorage.getItem('jobpreferences')
        ? '&labels=' + JSON.parse(window.localStorage.getItem('jobpreferences')).toString()
        : '')
  }

  return (
    <ErrorBoundary view={'jobwidget'} isWidget={true} {...errorOptions}>
      <Async
        promiseFn={fetchData}
        path={jobPreferenceParams ? jobPreferenceParams : slotIds[screen]}
        jobWidget={true}
        watch={screen}
        initialValue={loading && { loading: true }}
        deferFn={actionGET}
      >
        {({ data, error, isPending, isFulfilled, run }) => {
          if (error || (data && data.length === 0)) {
            errorLog({ error: error || 'Empty Result', info: 'BlockJobs' })
            ReactGA.event({
              category: 'Jobwidget Error',
              action: slotIds[screen].includes('fbf8f8a3-8364-43a3-a54b-eef3badd94cb')
                ? 'Web-Start-Desktop'
                : slotIds[screen].includes('322fd3cd-ec08-481f-8167-6c6edc3f05e0')
                ? 'Web-Start-Tablet'
                : slotIds[screen].includes('9fb67308-e690-4059-a641-a4e680f74f04')
                ? 'Web-Start-Mobil'
                : slotIds[screen].includes('153aa858-3d38-4e4e-be0b-eab85aa385d2')
                ? 'App-Start-Tablet'
                : slotIds[screen].includes('a8eaafda-78ec-49ea-91fb-0ff1e1f58421')
                ? 'App-Start-Mobil'
                : slotIds[screen].includes('d07c980d-f8fe-405f-b638-aeb06413e93a')
                ? 'Web-Kategorie-Desktop'
                : slotIds[screen].includes('973b5803-8d37-487d-bfe4-5c35d8e6e0ad')
                ? 'Web-Kategorie-Tablet'
                : slotIds[screen].includes('59a49673-67ff-48c7-84ec-161dad52b119')
                ? 'Web-Kategorie-Mobil'
                : slotIds[screen].includes('102076c7-319c-4934-bb4c-82d7c039c1f5')
                ? 'App-Kategorie-Tablet'
                : slotIds[screen].includes('f0771162-7272-4e84-b71b-93ed9c8a63ac')
                ? 'App-Kategorie-Mobil'
                : slotIds[screen].includes('b8b5a220-ba31-4a3b-8856-7ec5ac222dd2')
                ? 'Web-Artikel-Desktop'
                : slotIds[screen].includes('e34bdfed-296f-4725-ae97-c1bd584a900c')
                ? 'Web-Artikel-Tablet'
                : slotIds[screen].includes('b7c0f4c3-74ba-4b7f-8bf9-ca731da40d26')
                ? 'Web-Artikel-Mobil'
                : slotIds[screen].includes('e7d39be4-3701-4e21-b6f9-14f69f82423e')
                ? 'App-Artikel-Tablet'
                : slotIds[screen].includes('51c933c6-3050-480b-a561-79fcde7650e9')
                ? 'App-Artikel-Mobil'
                : 'Staging',
              label: error ? (error.status ? error.status.toString() : error.toString()) : 'Empty Result',
            })

            return false
          }
          return (
            <Wrapper id="JobWidgetContainer" {...props}>
              <StyledTitleWrapper>
                <TitleIcon />
                <StyledTitle>{title}</StyledTitle>
              </StyledTitleWrapper>
              {stateShowBranchChoice.show && (
                <BranchChoice handleClose={showBranchChoice} run={run} branches={branchchoice_options.branchOptions} />
              )}
              {!stateShowBranchChoice.show && (
                <StyledJobsWrapper>
                  {(((data && data.loading) || isPending) &&
                    Array(Math.max(...Object.values(numberItems)))
                      .fill(null)
                      .map(() => (
                        <StyledJobElementWrapper numberItems={numberItems}>
                          <SkeletonJobElement isWidget={true} />
                        </StyledJobElementWrapper>
                      ))) ||
                    data.map((job) => {
                      return (
                        <StyledJobElementWrapper numberItems={numberItems}>
                          <JobElement
                            data={
                              isFulfilled && {
                                link: job && job['target_url'] ? job['target_url'] : '',
                                logo: job && job['logo_url'],
                                image: job && job['image_url'],
                                employer_name: job && job['company_name'],
                                video: job && job['video_url'] ? job['video_url'] : '',
                                type:
                                  job &&
                                  job.properties['jobads-employmenttype'] &&
                                  job.properties['jobads-employmenttype'][0],
                                title: job && job.title ? job.title : '',
                                location:
                                  job &&
                                  (job['city']
                                    ? job['city']
                                    : job.properties['jobads-district']
                                    ? job.properties['jobads-district'][0]
                                    : null),
                                partner: job && job.properties['jobads-origin'] && job.properties['jobads-origin'][0],
                                sectors: job && job.types && job.types['jobads-type'],
                              }
                            }
                            isWidget={true}
                          />
                        </StyledJobElementWrapper>
                      )
                    })}
                </StyledJobsWrapper>
              )}
              <StyledPoweredBy partner={props.partner}>
                {!shouldBranchChoiceButtonLoad && (
                  <StyledAllButton to={isSTN ? '/jobangebote' : routes.getOptions('special.jobwidget').path}>
                    {allButtonText}
                  </StyledAllButton>
                )}
                {shouldBranchChoiceButtonLoad && (
                  <ButtonHolderBranchChoiceMobile>
                    <StyledAllButtonWitBranchChoice
                      to={isSTN ? '/jobangebote' : routes.getOptions('special.jobwidget').path}
                    >
                      {allButtonText}
                    </StyledAllButtonWitBranchChoice>
                    <StyledAllButtonBranchChoice onClick={() => showBranchChoice(run)}>
                      Branchen
                      <StyledBranchChoiceSvg id={'branchchoicesvg'} />
                    </StyledAllButtonBranchChoice>
                    <StyledAllButtonBranchChoiceMobile
                      id={'branchchoicebuttonmobile'}
                      onClick={() => showBranchChoice(run)}
                    >
                      <StyledBranchChoiceSvgMobile id={'branchchoicesvg'} />
                    </StyledAllButtonBranchChoiceMobile>
                  </ButtonHolderBranchChoiceMobile>
                )}
              </StyledPoweredBy>
            </Wrapper>
          )
        }}
      </Async>
    </ErrorBoundary>
  )
}

BlockJobs.propTypes = {
  numberItems: {
    desktop: PropTypes.number,
    tablet: PropTypes.number,
    mobile: PropTypes.number,
  },
}

BlockJobs.defaultProps = {
  numberItems: {
    desktop: 6,
    tablet: 3,
    mobile: 3,
  },
  allButtonText: 'Alle Stellenanzeigen',
  title: translate('jobs_block_title'),
  TitleIcon: StyledJobsLogo,
}

export default memo(BlockJobs)

export {
  Wrapper as BlockJobs0Wrapper,
  StyledAllButton as BlockJobs0StyledAllButton,
  StyledTitle as BlockJobs0StyledTitle,
  StyledPoweredBy as BlockJobs0StyledPoweredBy,
  StyledJobElementWrapper as BlockJobs0StyledJobElementWrapper,
  StyledTitleWrapper as BlockJobs0StyledTitleWrapper,
  StyledJobsLogo as BlockJobs0StyledJobsLogo,
}
